import '../../utils/rem.js'
import "../../scss/bootstrap.scss";
import "../../scss/glide.scss";
import "../../scss/common.scss";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "../../../node_modules/swiper/swiper-bundle.min.css";
import "../../../node_modules/swiper/swiper-bundle.min.js";
import "./page.css";



function changeBg() {
  var topnav = document.getElementById("topnav");
  var scrollValue = window.scrollY;
  if (window.screen.width > 768 && scrollValue < window.innerHeight) {
    topnav.classList.remove("navbar-light");
    topnav.classList.remove("bg-light90");
    topnav.classList.add("navbar-dark");
  } else if (window.screen.width > 768 && scrollValue > window.innerHeight) {
    topnav.classList.add("navbar-light");
    topnav.classList.remove("navbar-dark");
    topnav.classList.add("bg-light90");

  }


}

var isone = true;
function changecollapse() {
  var collapsed = document.querySelector('.navbar-toggler').classList.contains('collapsed')
  if (window.screen.width < 768 && window.scrollY < window.innerHeight) {

    var topnav = document.getElementById("topnav");
    if (!collapsed) {
      isone = false
      topnav.classList.add("navbar-light");
      topnav.classList.remove("navbar-dark");
      topnav.classList.add("bg-light90");
    } else {
      isone = true;
      topnav.classList.remove("navbar-light");
      topnav.classList.add("navbar-dark");
      setTimeout(() => {
        topnav.classList.remove("bg-light90");

      }, 200);
    }
  }
}

window.onload = function () {
  document.querySelector('.navbar-toggler').addEventListener('click', changecollapse, false)

  if (/Mac/i.test(navigator.platform)) {
    console.log("这是Mac操作系统平台");
    var explorer = window.navigator.userAgent;
    //判断是否为IE浏览器
    if (explorer.indexOf("MSIE") >= 0) {
    }
    //判断是否为Firefox浏览器
    else if (explorer.indexOf("Firefox") >= 0) {
    }
    //判断是否为Chrome浏览器
    else if (explorer.indexOf("Chrome") >= 0) {
      document.getElementById('swiper-button-prev').style.left = '16%';
      document.getElementById('swiper-button-next').style.right = '16%';
      document.getElementById('swiper3d-desc1').style.left = '9%';
      document.getElementById('swiper3d-desc2').style.left = '9%';
      document.getElementById('swiper3d-desc3').style.left = '9%';
      document.getElementById('swiper3d-desc4').style.left = '9%';
      document.getElementById('swiper3d-desc5').style.left = '9%';
      document.getElementById('swiper3d-desc6').style.left = '9%';
    }
    //判断是否为Opera浏览器
    else if (explorer.indexOf("Opera") >= 0) {
    }
    //判断是否为Safari浏览器
    else if (explorer.indexOf("Safari") >= 0) {
      document.getElementById('swiper-button-prev').style.left = '9%';
      document.getElementById('swiper-button-next').style.right = '9%';
      document.getElementById('swiper3d-desc1').style.left = '10%';
      document.getElementById('swiper3d-desc2').style.left = '10%';
      document.getElementById('swiper3d-desc3').style.left = '10%';
      document.getElementById('swiper3d-desc4').style.left = '10%';
      document.getElementById('swiper3d-desc5').style.left = '10%';
      document.getElementById('swiper3d-desc6').style.left = '10%';
    }
  }




  if (window.screen.width < 768) {
    setEllipsis('app-Ellipsis01', 52);
    setEllipsis('app-Ellipsis02', 52);
    setEllipsis('app-Ellipsis03', 52);
  } else {
    // setEllipsis('new-title01', 27);
    // setEllipsis('new-title02', 27);
    // setEllipsis('new-title03', 27);
    // setEllipsis('new-title04', 27);
    // setEllipsis('new-desc01', 52);
    // setEllipsis('new-desc02', 52);
    // setEllipsis('new-desc03', 52);
    // setEllipsis('new-desc04', 52);
  }


  new Swiper(".appmySwiper", {
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  })


  //新闻的swiper
  var swiper = new Swiper(".mySwiper", {
    slidesPerView: 3,
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      slideChange: function () {
        if (swiper) {
          var num = swiper.activeIndex + 2;
          for (let i = 1; i <= 4; i++) {
            var id = 'display' + i;
            var opcity = 'sweiper-opcity' + i;
            document.getElementById(id).classList.remove('pc-swiper-display-block');
            document.getElementById(id).classList.add('pc-swiper-display-none');
            document.getElementById(opcity).classList.add('pc-swiper-opcity');
          }
          var activeIndex = 'display' + num;
          var opcityIndex = 'sweiper-opcity' + num;
          document.getElementById(opcityIndex).classList.remove('pc-swiper-opcity');
          document.getElementById(activeIndex).classList.remove('pc-swiper-display-none');
          document.getElementById(activeIndex).classList.add('pc-swiper-display-block');
        }
      }
    }
  });


  const swiper3d = new Swiper('.swiper3d', {
    effect: 'coverflow',
    loop: true,
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 300,
      modifier: 1,
      slideShadows: false,
    },
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-prev',
      prevEl: '.swiper-button-next',
    },
  });



  //获取元素到顶部的距离
  function findPosY(el, val) {
    //obj为所要计算的元素,可用id或class获取
    let actualTop = el.offsetTop;
    let current = el.offsetParent;
    while (current !== null) {
      actualTop += current.offsetTop;
      current = current.offsetParent;
    }
    return actualTop;
  };


  //数字增长
  function custumGrow(num, ele) {
    let time = 1;
    let step = Math.ceil((num * 10) / (time * 1000));
    let current = 0;
    let start = 0;
    let t = setInterval(() => {
      start += step;
      if (start > num) {
        clearInterval(t);
        start = num;
        t = null;
      }
      if (current === start) {
        return;
      }
      current = start;
      ele.innerHTML = current.toString();
    }, 10);
  };

  //用来执行一次的数字滚动
  var curtime = true;

  //初始化滚动的数字
  document.getElementById('count').innerHTML = 0;
  document.getElementById('count1').innerHTML = 0;
  document.getElementById('count2').innerHTML = 0;

  document.getElementById('home-business-model-box').style.visibility = 'hidden'
  function changeparallax() {
    var scrollValue = window.scrollY;
    var home_content =
      findPosY(document.getElementById("home-bag-dis"));

    if (scrollValue > window.innerHeight) {
      //头部固定
      document.getElementById('home-content').classList.remove('home-header-fix')
      document.getElementById('home-content').classList.add('home-header-nofix')
      document.getElementById('distance-vh').style.display = 'none'


      //关于景合数字滚动
      var scollNum = findPosY(document.getElementById("home-parallax")) - window.innerHeight;
      if (scrollValue > scollNum) {
        if (curtime) {
          document.getElementById('unit').innerHTML = '＋';
          document.getElementById('unit1').innerHTML = '万＋';
          document.getElementById('unit2').innerHTML = '万＋';
          custumGrow(10, document.getElementById('count'));
          custumGrow(80, document.getElementById('count1'));
          custumGrow(300, document.getElementById('count2'));
          curtime = false;
        }
      }


      //视差
      var parallaxStart = findPosY(document.getElementById('home-parallax')) - window.innerHeight;
      var parallaxEnd = findPosY(document.getElementById('home-font-scoll')) - window.innerHeight / 2;

      if (scrollValue > parallaxStart && scrollValue < parallaxEnd) {
        var distance = (scrollValue - parallaxStart) / 7
        document.getElementById('parallax1').style.transform = `translateX(${distance}px)`
        document.getElementById('parallax3').style.transform = `translateX(${distance}px)`
        document.getElementById('parallax2').style.transform = `translateX(-${distance}px)`
      }


      //数字滚动
      //pc端
      var showfont1 = findPosY(document.getElementById("showfont1")) - window.innerHeight;
      var showfont2 = findPosY(document.getElementById("showfont2")) - window.innerHeight;
      var showfont3 = findPosY(document.getElementById("showfont3")) - window.innerHeight;
      var showfont4 = findPosY(document.getElementById("showfont4")) - window.innerHeight;
      var showfont5 = findPosY(document.getElementById("showfont5")) - window.innerHeight;
      var showfont6 = findPosY(document.getElementById("showfont6")) - window.innerHeight;
      var showfont7 = findPosY(document.getElementById("showfont7")) - window.innerHeight;
      var showfont8 = findPosY(document.getElementById("showfont8")) - window.innerHeight;

      //获取每行的宽度值
      //pc
      var fontdistance1 = Math.floor((scrollValue - showfont1) / 10);
      var fontdistance2 = Math.floor((scrollValue - showfont2) / 9);
      var fontdistance3 = Math.floor((scrollValue - showfont3) / 8);
      var fontdistance4 = Math.floor((scrollValue - showfont4) / 7);
      var fontdistance5 = Math.floor((scrollValue - showfont5) / 6);
      var fontdistance6 = Math.floor((scrollValue - showfont6) / 5);
      var fontdistance7 = Math.floor((scrollValue - showfont7) / 4);
      var fontdistance8 = Math.floor((scrollValue - showfont8) / 3);
      document.getElementById('showfont1').style.width = `${fontdistance1}%`;
      document.getElementById('showfont2').style.width = `${fontdistance2}%`
      document.getElementById('showfont3').style.width = `${fontdistance3}%`
      document.getElementById('showfont4').style.width = `${fontdistance4}%`
      document.getElementById('showfont5').style.width = `${fontdistance5}%`
      document.getElementById('showfont6').style.width = `${fontdistance6}%`
      document.getElementById('showfont7').style.width = `${fontdistance7}%`
      document.getElementById('showfont8').style.width = `${fontdistance8}%`

      //app端
      var appshowfont1 = findPosY(document.getElementById("appshowfont1")) - window.innerHeight;
      var appshowfont2 = findPosY(document.getElementById("appshowfont2")) - window.innerHeight;
      var appshowfont3 = findPosY(document.getElementById("appshowfont3")) - window.innerHeight;
      var appshowfont4 = findPosY(document.getElementById("appshowfont4")) - window.innerHeight;
      var appshowfont5 = findPosY(document.getElementById("appshowfont5")) - window.innerHeight;
      var appshowfont6 = findPosY(document.getElementById("appshowfont6")) - window.innerHeight;
      var appshowfont7 = findPosY(document.getElementById("appshowfont7")) - window.innerHeight;
      var appshowfont8 = findPosY(document.getElementById("appshowfont8")) - window.innerHeight;
      var appshowfont9 = findPosY(document.getElementById("appshowfont9")) - window.innerHeight;
      var appshowfont10 = findPosY(document.getElementById("appshowfont10")) - window.innerHeight;
      var appshowfont11 = findPosY(document.getElementById("appshowfont11")) - window.innerHeight;
      var appshowfont12 = findPosY(document.getElementById("appshowfont12")) - window.innerHeight;
      // var appshowfont13 = findPosY(document.getElementById("appshowfont13")) - window.innerHeight;
      //app
      var appfontdistance1 = Math.floor((scrollValue - appshowfont1) / 5);
      var appfontdistance2 = Math.floor((scrollValue - appshowfont2) / 2);
      var appfontdistance3 = Math.floor((scrollValue - appshowfont3) / 9);
      var appfontdistance4 = Math.floor((scrollValue - appshowfont4) / 2);
      var appfontdistance5 = Math.floor((scrollValue - appshowfont5) / 6);
      var appfontdistance6 = Math.floor((scrollValue - appshowfont6) / 2);
      var appfontdistance7 = Math.floor((scrollValue - appshowfont7) / 4);
      var appfontdistance8 = Math.floor((scrollValue - appshowfont8) / 3);
      var appfontdistance9 = Math.floor((scrollValue - appshowfont9) / 2);
      var appfontdistance10 = Math.floor((scrollValue - appshowfont10) / 2);
      var appfontdistance11 = Math.floor((scrollValue - appshowfont11) / 2);
      var appfontdistance12 = Math.floor((scrollValue - appshowfont12) / 1);
      // var appfontdistance13 = Math.floor((scrollValue - appshowfont13) / 10);
      document.getElementById('appshowfont1').style.width = `${appfontdistance1}%`;
      document.getElementById('appshowfont2').style.width = `${appfontdistance2}%`
      document.getElementById('appshowfont3').style.width = `${appfontdistance3}%`
      document.getElementById('appshowfont4').style.width = `${appfontdistance4}%`
      document.getElementById('appshowfont5').style.width = `${appfontdistance5}%`
      document.getElementById('appshowfont6').style.width = `${appfontdistance6}%`
      document.getElementById('appshowfont7').style.width = `${appfontdistance7}%`
      document.getElementById('appshowfont8').style.width = `${appfontdistance8}%`
      document.getElementById('appshowfont9').style.width = `${appfontdistance9}%`
      document.getElementById('appshowfont10').style.width = `${appfontdistance10}%`
      document.getElementById('appshowfont11').style.width = `${appfontdistance11}%`
      document.getElementById('appshowfont12').style.width = `${appfontdistance12}%`
      // document.getElementById('appshowfont13').style.width = `${appfontdistance13}%`


      //技术优势
      var hometecadvantage = findPosY(document.getElementById("hometecadvantage")) - window.innerHeight;
      var homebusinesscooperation = findPosY(document.getElementById("home-business-cooperation")) - window.innerHeight;
      var homemiddle = (homebusinesscooperation - hometecadvantage) / 1.5 + hometecadvantage;
      var scalenum = 2 - (scrollValue - hometecadvantage) /
        Math.pow(10, (homebusinesscooperation - hometecadvantage).toString().length);
      console.log(scrollValue, homemiddle)
      if (scrollValue > hometecadvantage && scrollValue < homemiddle) {
        document.getElementById('icon_data_base01').style.transform = `translateX(-${scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum}rem) scale(${scalenum * scalenum * scalenum})`;
        document.getElementById('icon_data_base02').style.transform = `translateX(-${scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum}rem) scale(${scalenum * scalenum})`;
        document.getElementById('icon_data_base03').style.transform = `translateX(-${scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum}rem) translateY(${scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum}rem)  scale(${scalenum * scalenum})`;
        document.getElementById('icon_data_base04').style.transform = `translateX(${scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum}rem) translateY(${scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum}rem)  scale(${scalenum * scalenum})`;
        document.getElementById('icon_data_base05').style.transform = `translateX(${scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum}rem) scale(${scalenum * scalenum})`;
        document.getElementById('icon_data_base06').style.transform = `translateX(${scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum * scalenum}rem) scale(${scalenum * scalenum})`;
      } else if (scrollValue > homemiddle) {
        document.getElementById('icon_data_base01').style.transform = 'translateX(0) translateY(0) scale(1)';
        document.getElementById('icon_data_base02').style.transform = 'translateX(0) translateY(0) scale(1)';
        document.getElementById('icon_data_base03').style.transform = 'translateX(0) translateY(0) scale(1)';
        document.getElementById('icon_data_base04').style.transform = 'translateX(0) translateY(0) scale(1)';
        document.getElementById('icon_data_base05').style.transform = 'translateX(0) translateY(0) scale(1)';
        document.getElementById('icon_data_base06').style.transform = 'translateX(0) translateY(0) scale(1)';
      }

      var homebusinessmodelbox = findPosY(document.getElementById("home-business-model-box")) - window.innerHeight;
      var homeswiperjinghe = findPosY(document.getElementById("home-swiper-jinghe")) - window.innerHeight;
      var jinghemiddle = homebusinessmodelbox + (homeswiperjinghe - homebusinessmodelbox) / 2;
      if (scrollValue > jinghemiddle) {
        document.getElementById('home-business-model-box').style.visibility = 'visible'
      } else {
        document.getElementById('home-business-model-box').style.visibility = 'hidden'
      }
      //业务合作模块

    } else {
      //头部固定
      document.getElementById('home-content').classList.remove('home-header-nofix')
      document.getElementById('home-content').classList.add('home-header-fix')
      document.getElementById('distance-vh').style.display = 'block';
      if (home_content < 0) {
        if (
          scrollValue > Math.abs(home_content) &&
          scrollValue < window.innerHeight
        ) {
          document.getElementById('home-header-zIndex').style.zIndex = -1
        } else {
          document.getElementById('home-header-zIndex').style.zIndex = 2
        }
      }
    }


  }
  window.addEventListener("scroll", changeparallax);
}




//文字省略
function setEllipsis(ele, index) { // 设置省略号
  var text = document.getElementById(ele);
  var result = "";
  if (text.innerText.length > index) {
    result = text.innerText.substr(0, index) + "...";
  } else {
    result = text.innerText;
  }
  text.innerText = result

}
function change() {
  var topnav = document.getElementById("topnav");
  var scrollValue = window.scrollY;
  var collapsed = document.querySelector('.navbar-toggler').classList.contains('collapsed')

  if (window.screen.width < 768 && scrollValue < window.innerHeight) {
    if(collapsed){
      isone = false
      topnav.classList.remove("navbar-light");
      topnav.classList.remove("bg-light90");
      topnav.classList.add("navbar-dark");
    }else{
      if (isone) {
        topnav.classList.remove("navbar-light");
        topnav.classList.remove("bg-light90");
        topnav.classList.add("navbar-dark");
      }
    }
  } else if (window.screen.width < 768 && scrollValue > window.innerHeight) {
    topnav.classList.add("navbar-light");
    topnav.classList.remove("navbar-dark");
    topnav.classList.add("bg-light90");
  }
}
window.addEventListener("scroll", changeBg);
window.addEventListener("scroll", change);


