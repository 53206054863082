(function(d, w) {
    const doc = d.documentElement;
    function rem() {
      let width = null;
      if(doc.clientWidth <= 375){
        width = Math.floor(doc.clientWidth * 16 / 375)  
      }else if(doc.clientWidth > 375 && doc.clientWidth <= 750){
        width = Math.floor(doc.clientWidth * 16 / 375)  
      }else if(doc.clientWidth > 750 && doc.clientWidth < 992){
        width = 16
       
      } else if(doc.clientWidth >= 992 && doc.clientWidth <= 1920){
        width = Math.floor(doc.clientWidth * 16 / 1920)
      }
     
      doc.style.fontSize = width + 'px';
    }
    rem();
    w.addEventListener('resize', rem);
  })(document, window);
